import * as React from 'react'
import Nav from "../components/nav"
import Footer from "../components/footer"
import Hero from "../components/hero"
import {Helmet} from "react-helmet"
import OpenGraphLogo from "../images/open-graph-logo.png"
import Imagepickleball from "../images/pickleball-page.jpg";

function PickleballTraining() {
    return (
        <div>
            <Helmet>
    <title>Pickleball Training & Injury Prevention | Kinetic Impact</title>
    <meta property="og:title" content="Pickleball Training & Injury Prevention | Kinetic Impact" />
    <meta property="og:image" content={OpenGraphLogo} />
    <meta property="og:description" content="Expert pickleball-specific conditioning and injury prevention programs. Specialized training for quick reactions, shoulder health, and lateral movement, with programs for all ages." />
    <meta name="description" content="Enhance your pickleball game with professional training and injury prevention. Our programs include quick reaction training, shoulder care, and specialized programs for active adults." />
    <meta name="keywords" content="pickleball training, pickleball conditioning, quick reaction training, shoulder injury prevention, lateral movement training, joint protection, senior pickleball programs" />
</Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/1st-timers"} 
                  header={"Stay Active and Competitive on the Pickleball Court"}>
                <div id={"content-start"} className={"flex flex-col justify center mx-auto px-4 lg:pt-8 pt-4 text-lg bg-white rounded-xl"}>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        Want to improve your game while avoiding common pickleball injuries? Our pickleball-specific conditioning programs are designed to enhance your performance and keep you playing the sport you love.
                    </p>
                    
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        From quick reaction training to joint protection techniques, we provide comprehensive support for players of all ages and skill levels.
                    </p>

                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto w-full"}>
                        Let's work together to enhance your game and keep you moving confidently on the court.
                    </p>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Let's Work Together!
                        </button>
                    </a>

                    <div className={"lg:w-1/2 w-full h-fit pt-12 mx-auto"}>
                        <img className={"rounded"} src={Imagepickleball} alt="Movement screening analysis"/>
                    </div>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Comprehensive Pickleball Training</h2>

                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Movement Assessment</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Our lateral movement training assessment identifies areas for improvement in your court coverage and agility. We'll help you develop the quick, controlled movements essential for pickleball success.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Injury Prevention</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Focus on shoulder injury prevention and joint protection techniques to keep you playing pain-free. Our programs emphasize proper mechanics and strengthening exercises to protect vulnerable areas.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Performance Enhancement</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Improve your game through quick reaction training and sport-specific drills. We'll help you develop the speed, agility, and coordination needed for competitive play.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Specialized Senior Programs</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Our senior-focused pickleball programs provide safe, effective training methods tailored to those in their golden years. We emphasize maintaining mobility, strength, and confidence on the court.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Start Your Training Today
                        </button>
                    </a>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Key Training Components</h2>

                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Movement Skills</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Expert guidance in lateral movement training and quick directional changes. We'll help you develop the footwork and balance needed for better court coverage and shot preparation.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Reaction Speed</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Enhance your quick reaction training with drills designed to improve response time and decision-making. Perfect for handling fast-paced exchanges at the net.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Joint Care</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Learn effective techniques to develop strength in key areas to help protect joints and ligaments. Our approach prioritizes shoulder injury prevention while maintaining optimal performance.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Custom Programming</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Receive pickleball-specific conditioning programs tailored to your goals and fitness level. Whether you're new to the sport or a seasoned player, we'll help you improve safely and effectively.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10 mb-4"}>
                            Enhance Your Pickleball Game Today
                        </button>
                    </a>
                </div>
            </Hero>
            <Footer/>
        </div>
    )
}

export default PickleballTraining